<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Custom User Fields
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col>
            <draggable :list="userContextInputs" handle=".handle">
              <v-list-item
                v-for="(inputField, index) in userContextInputs"
                :key="index"
              >
                <v-list-item-title>
                  <v-row align="center" dense>
                    <v-col class="shrink mb-7">
                      <v-btn :style="{ cursor: 'grab' }" class="handle" icon>
                        <v-icon>mdi-swap-vertical</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="grow py-2">
                      <v-text-field
                        v-model.trim="inputField.name"
                        :label="`Custom field ${index + 1}`"
                        :placeholder="inputField.label"
                        clearable
                        counter
                        dense
                        maxlength="75"
                        outlined
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
            </draggable>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col align="right">
            <v-btn
              :disabled="isSaveDisabled"
              @click="save"
              class="white--text msaBlue"
            >
              SAVE
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'CompanySettingsInputFieldPage',
  components: {
    draggable,
  },
  data() {
    return {
      // In future, there will be custom Project fields (ProjectsContext) so I'm specifying that these for the UsersContext
      userContextInputs: [],
      unchangedUserContextInputs: [],
    };
  },
  computed: {
    isSaveDisabled() {
      // Handles CLEAR action on field
      for (const field of this.userContextInputs) {
        if (field.name == null) {
          field.name = '';
        }
      }

      const isChanges =
        JSON.stringify(this.userContextInputs) !=
        JSON.stringify(this.unchangedUserContextInputs);

      if (!isChanges) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    const url = 'get-input-fields-for-organization?format=json';
    const params = {
      loaderText: 'Loading...',
      orgId: this.$store.getters.user.companyId,
    };

    this.$axios.post(url, params).then((response) => {
      this.populateList(response);
      this.createEmptyFields();
    });
  },
  methods: {
    save() {
      const url = 'update-input-fields-for-organization?format=json';
      const params = {
        loaderText: 'Loading...',
        // Remove fields that won't be used to action items
        inputFields: this.userContextInputs.filter((inputField) => {
          return inputField.name?.length || inputField?.id;
        }),
      };

      this.$axios.post(url, params).then((response) => {
        this.populateList(response);
        this.createEmptyFields();
      });
    },
    createEmptyFields() {
      if (this.userContextInputs.length < 3) {
        for (
          let numItems = this.userContextInputs.length;
          numItems < 3;
          numItems++
        ) {
          this.userContextInputs.push({
            name: '',
            position: this.userContextInputs.length,
          });
          this.unchangedUserContextInputs.push({
            name: '',
            position: this.unchangedUserContextInputs.length,
          });
        }
      }
    },
    populateList(response) {
      this.userContextInputs = response.data;
      this.unchangedUserContextInputs = JSON.parse(
        JSON.stringify(response.data),
      );
    },
  },
};
</script>
